<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="{ name: 'booking', params: { bookingId: purchase.id, lang: spflocale } }"
    custom
  >
    <a
      :href="href"
      class="matchcard card bg-white text-dark text-decoration-none d-flex flex-column justify-content-between h-100"
      style="border-radius: 8px; border-color: var(--gray-card)"
      @click="navigate"
    >
      <div class="px-3 pt-4">
        <div class="d-flex flex-column">
          <!-- <div class="pb-1">
            <center-attributes :center="center" />
          </div> -->

          <div class="mb-3">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bold">
                {{
                  capitalize(
                    purchase.date_from.toLocaleDateString(spflocale, {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  )
                }},
              </span>
              <b-badge
                v-if="purchase.sport"
                variant="dark"
                class="d-flex align-items-center"
                pill
              >
                {{ capitalize(purchase.sport.internal_name) }}
              </b-badge>
            </div>
            <div>
              {{ formattedTime }}
            </div>
          </div>

          <match-card-venue
            :center="center"
            :price="price"
          />
        </div>
        <hr class="mb-2" />
        <p class="mt-0 mb-1">
          {{
            transChoice(`booking.default.placesAvailable`, freeSpots, {
              count: freeSpots,
              total: purchase.player_count,
            })
          }}
        </p>

        <match-card-tags
          :purchase="purchase"
          :show-gender="showGender"
        />
      </div>

      <div class="p-3">
        <slot>
          <match-card-action :purchase="purchase" />
        </slot>
      </div>
    </a>
  </router-link>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SluggableEntityMixin from '../../mixins/SluggableEntityMixin';
import MatchCardVenue from './MatchCardVenue.vue';
import MatchCardTags from './MatchCardTags.vue';
import MatchCardAction from './MatchCardAction.vue';
// import CenterAttributes from '../../center/CenterAttributes.vue';
const prefix0 = (v) => (v < 10 ? `0${v}` : v);

export default {
  name: 'MatchCard',
  components: { MatchCardAction, MatchCardTags, MatchCardVenue },
  mixins: [SluggableEntityMixin],
  props: {
    showDate: { type: Boolean, required: false, default: true },
    showSport: { type: Boolean, required: false, default: true },
    showFull: { type: Boolean, required: false, default: true },
    showGender: { type: Boolean, required: false, default: true },
    center: { type: Object, required: true },
    purchase: { type: Object, required: true },
  },
  computed: {
    ...mapFields('match', ['attributes']),
    price() {
      if (this.purchase.price_per_person) {
        return `${this.purchase.price_per_person}€ ${this.trans('voc.per_person')}`;
      }

      return `${this.purchase.amount}€`;
    },
    freeSpots() {
      const busySpots = Array.isArray(this.purchase.active_players)
        ? this.purchase.active_players.length
        : Object.keys(this.purchase.active_players).length;
      return this.purchase.player_count - busySpots;
    },
    formattedTime() {
      return `${prefix0(this.purchase.date_from.getHours())}:${prefix0(this.purchase.date_from.getMinutes())} - ${prefix0(this.purchase.date_to.getHours())}:${prefix0(this.purchase.date_to.getMinutes())}`;
    },
  },
};
</script>
