<template>
  <div
    :class="containerClass"
    style="gap: 8px"
  >
    <slot></slot>
    <component
      :is="compo"
      v-for="e of resource.attributes"
      v-bind="$attrs"
      :key="e.id"
      pill
      class="border text-gray-text"
      variant="transparent"
    >
      <attribute-text
        :definition="e.definition"
        :value="e.value"
        :boolean-value="e.boolean_value"
      />
    </component>
  </div>
</template>

<script>
import AttributeText from './common/AttributeText.vue';

export default {
  components: { AttributeText },
  props: {
    resource: { type: Object, required: true },
    compo: { type: String, required: false, default: 'b-badge' },
    containerClass: { type: String, required: false, default: 'd-flex py-1 flex-wrap' },
  },
};
</script>
