<template>
  <div>
    <resource-attributes
      v-for="r of purchase.resources"
      :key="r.id"
      short
      :resource="r"
    >
      <template #default>
        <b-badge
          v-if="purchase.level_min || purchase.level_max"
          variant="transparent"
          class="border text-gray-text"
          pill
        >
          <template v-if="purchase.level_min">
            {{ getSluggableEntityName(purchase.level_min?.descriptions) }}
          </template>
          <template v-if="purchase.level_max">
            -
            {{ getSluggableEntityName(purchase.level_max?.descriptions) }}
          </template>
        </b-badge>

        <b-badge
          v-if="showGender"
          variant="transparent"
          class="border text-gray-text"
          pill
        >
          <template v-if="!purchase.gender || purchase.gender === 'mixed'">
            <i class="fa fa-venus" />
            <i class="fa fa-mars" />
          </template>
          <i
            v-else
            class="fa"
            :class="`fa-${purchase.gender === 'm' ? 'mars' : 'venus'}`"
          />
          <span class="d-none d-lg-inline-block ml-1">
            {{ trans(`form.genderType.${purchase.gender || 'mixed'}`) }}
          </span>
        </b-badge>
      </template>
    </resource-attributes>
  </div>
</template>

<script>
import SluggableEntityMixin from '../../mixins/SluggableEntityMixin';
import ResourceAttributes from '../../ResourceAttributes.vue';

export default {
  components: { ResourceAttributes },
  mixins: [SluggableEntityMixin],
  props: {
    purchase: { type: Object, required: true },
    showGender: { type: Boolean, required: false, default: true },
  },
};
</script>
