<template>
  <b-sidebar
    :visible="isOpen"
    right
    lazy
    width="430px"
    header-class="text-right pb-0"
    bg-variant="white"
    backdrop
    class="infobox__sidebar"
    @hidden="handleClose"
  >
    <div class="pb-5 pre-formatted">
      <div
        v-if="!leadRaw"
        class="fs-5 font-weight-bold mb-3"
      >
        {{ trans(lead) }}
      </div>

      <div
        v-if="leadRaw"
        v-html="leadRaw"
      />

      <div v-if="!contentRaw">{{ trans(content) }}</div>

      <div
        v-if="contentRaw"
        v-html="contentRaw"
      />
    </div>
  </b-sidebar>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields('infobox', ['isOpen', 'content', 'contentRaw', 'lead', 'leadRaw']),
  },
  methods: {
    handleClose() {
      this.$store.dispatch('infobox/close');
    },
  },
};
</script>
