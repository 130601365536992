<template>
  <b-button
    block
    size="lg"
    class="font-weight-bold fs-3"
    :class="cssClass"
    :variant="variant"
  >
    {{ label }}
  </b-button>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.common.js';

export default {
  props: {
    purchase: { type: Object, required: true },
  },
  computed: {
    ...mapState('user', ['user']),
    canJoin() {
      return (
        !!this.purchase &&
        !!this.purchase.actions &&
        (this.purchase.actions.includes('join') || this.purchase.actions.includes('request'))
      );
    },
    itsMyMatch() {
      return this.purchase.buyer === `/api/users/${this.user?.id}`;
    },
    label() {
      if (this.itsMyMatch) {
        return this.trans('matches.yours');
      }

      if (this.canJoin) {
        return this.capitalize(this.trans('general.join'));
      }

      return this.trans('booking.summary.seeDetails');
    },
    variant() {
      if (this.itsMyMatch) {
        return 'dark';
      }
      if (this.canJoin) {
        return 'success-light';
      }
      return 'orange-light';
    },
    cssClass() {
      if (this.itsMyMatch) {
        // return 'light text-success';
        return 'bg-dark text-light';
      }

      if (this.canJoin) {
        // return 'light text-success';
        return 'bg-success-lighter text-success';
        // return 'primary-reduced';
      }

      return 'bg-orange-lighter text-primary';
    },
  },
};
</script>
